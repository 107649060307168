import React from "react";
import Slider from "react-slick";
import styles from "./Supportedby.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Supportedby() {
    var settings = {
        adaptiveHeight: true,
        autoplay: true,
        infinite: true,
        dots: false,
        arrows: false,
        autoplaySpeed: 1000,
        variableWidth: true,
        speed: 500,
        mobileFirst:true,
        responsive: [
            {
              breakpoint: 770,
              settings: {
                centerMode: true,
                centerPadding: '0px',
                slidesToShow: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                centerMode: true,
                centerPadding: '0px',
                slidesToShow: 1
              }
            },
        ]
    };

    return(
        <section className={styles.sponsors} >
                <p>SUPPORTED BY</p>
                <Slider {...settings}>
                    <div className={styles.sponsors_div}>
                        <a href="https://slmch.ac.in/" target="_blank"><img className={styles.image} src="./image-5@2x.png" height="60px"/></a>
                    </div>
                    <div className={styles.sponsors_div}>
                        <a href="https://ablest.sastra.edu/" target="_blank"><img className={styles.image} src="./image-6@2x.png" height="60px"/></a>
                    </div>
                    <div className={styles.sponsors_div}>
                        <a href="https://www.msmetamilnadu.tn.gov.in/edii.php" target="_blank"><img className={styles.image} src="./editnlogo (1).jpg" height="60px"/></a>
                    </div>
                    <div className={styles.sponsors_div}>
                        <a href="https://www.sastra.edu/" target="_blank"><img className={styles.image} src="./sas376nirf.jpg" height="60px"/></a>
                    </div>
                    <div className={styles.sponsors_div}>
                        <a href="https://www.nidhi-prayas.org/" target="_blank"><img className={styles.image} src="./dst nidhi prayas.png" height="60px"/></a>
                    </div>
                </Slider>
        </section>
    );
}