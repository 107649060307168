import "./Paceb-video.css";
const Paceb_video = () => {
	return (
		<div className="video-container">
			<iframe
				className="video"
				src="https://www.youtube.com/embed/EWRJDsj5hJc"
				allowfullscreen=""
				allow="fullscreen"
			></iframe>{" "}
		</div>
	);
};
export default Paceb_video;
