import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Contactus from "../components/Contactus"
import "./ContactUs.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
const ContactUs = () => {
  const navigate = useNavigate();

  
  return (
    <div className="contact-us8">
      <Navbar/>
      <Contactus/>
      <Footer/>
    </div>
  );
};

export default ContactUs;
