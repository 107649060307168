import "./Vision.css"

const Vision = () => {
    return(
        <div className="vision_container">
            <a>VISION</a>
            <h2 className="header-text">Innovation For Impact: Aspiring To Disrupt</h2>
            <p>Technology can be a powerful tool for challenging traditional problem-solving methodologies and creating lasting impact. We are committed to delivering comprehensive and innovative end-to-end solutions that address the root causes of complex problems.</p>
            <p className="sub-desc">Discover how we can help you!</p>
            {/* <a href="https://drive.google.com/file/d/1nz3-SmvTM6_1e_6F4KCq-kQpEajkzKuP/preview"><button className="my-button">KNOW MORE</button></a> */}
        </div>
    )
}
export default Vision;