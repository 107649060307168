import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import style from "./Tac.css";

function TermsAndConditions() {
    const [docContent, setDocContent] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDocContent = async () => {
            try {
                const docUrl = "https://docs.google.com/document/d/1lLYIBd-3SndGsjegl8S-m8CCrfV6aJL-82ZFHNgcGH4/pub?output=html";

                const response = await fetch(docUrl);
                const text = await response.text();

                let cleanedText = text
                    .replace(/Updated automatically every 5 minutes/g, '')
                    .replace(/Published using Google Docs/g, '')
                    .replace(/Learn more/g, '');

                cleanedText = cleanedText
                    .replace(/<div[^>]*id=["']title-banner["'][^>]*>.*?<\/div>/gs, '')
                    .replace(/<div[^>]*id=["']publish-banner["'][^>]*>.*?<\/div>/gs, '')
                    .replace(/<div[^>]*id=["']publish-banner-buttons["'][^>]*>.*?<\/div>/gs, '')
                    .replace(/<style[^>]*>([\s\S]*?)<\/style>/gi, (match, p1) => {
                        let cleanStyle = p1
                            .replace(/padding:\s*72+pt\s*72+pt\s*72+pt\s*72+pt\s*;?/g, 'padding: 0;')
                            .replace(/max-width:\s*\d+(\.\d+)?pt;/g, 'max-width: 100%;')
                            .replace(/#contents\s*{[^}]*}/gi, '');
                        return `<style>${cleanStyle}</style>`;
                    });

                setDocContent(cleanedText);
            } catch (error) {
                setError("Error fetching document.");
                console.error("Error fetching document:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchDocContent();
    }, []);

    return (
        <div className="terms-conditions-container">
            <Navbar className={style.Navbar} />

            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <>
                    <div
                        dangerouslySetInnerHTML={{ __html: docContent }}
                    />
                </>
            )}

            <Footer />
        </div>
    );
}

export default TermsAndConditions;
