import Footer from "../Footer";
import styles from "./BlogComponent.module.css";
import Navbar from "../Navbar";
const Blog1 = () => {
    return (
      <div className="blog">
      {/* <Navbar/> */}
      <div className={styles.blogContainer}>
<iframe className={styles.blog1}src="https://docs.google.com/document/d/e/2PACX-1vQF5Nw_dziXhSXx7Pm1x3mOz49_GSwptmwYJklxCvQUl1Mo24OdGDvWyT4w6bQGqMzxWHF7jO7sacLL/pub?embedded=true"></iframe>
      </div>
      {/* <Footer/> */}
      </div>

    );

}
export default Blog1;