import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import  "../pages/Blog.css"
import Contactus from "../components/Contactus"
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import { useParams } from 'react-router-dom';

import BlogComponent from "../components/Blog/BlogComponent";
const Blog = () => {
  const navigate = useNavigate();
  const { blogid } = useParams();
console.log(blogid);
  return (
    <div className="blog">
      <Navbar/>
      {blogid ? <BlogComponent/> : <BlogComponent/>}
      {/* <BlogComponent/> */}
      <Footer/>
    </div>
  );
}
export default Blog;