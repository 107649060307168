import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Gallery.css";

const Gallery = () => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true, // Enable automatic scrolling
		autoplaySpeed: 3000, // Set the time interval between slides in milliseconds
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<div className="Gallery-container">
			<p className="Gallery-header">GALLERY</p>
			<div className="Galleryimage-container">
				<Slider {...settings}>
					<img src="./G_8.png" alt="Image 8" />
					<img src="./IMG_4222.png" alt="Image 9" />

					<img src="./G_2.png" alt="Image 2" />

					{/* <img src="./G_3.png" alt="Image 3" /> */}

					<img src="./G_5.png" alt="Image 5" />
					<img src="./Newimages/image.jpg" alt="Image 1" />
					{/* <img src="./Newimages/2.jpg" alt="Image 2" /> */}

					<img src="./Newimages/4.jpg" alt="Image 4" />
					<img src="./Newimages/6.png" alt="Image 6" />
					<img src="./Newimages/new.png" alt="Gr" />
					{/* <img src="./Newimages/7.jpg" alt="Image 7" /> */}


					{/* <img src="./Apollo.jpg" alt="Image 4" /> */}
					{/* <img src="./G_4.png" alt="Image 4" />
					<img src="./G_7.png" alt="Image 7" /> */}
					{/* <img src="./G_6.png" alt="Image 6" /> */}

					{/* <img src="./G_1.png" alt="Image 1" /> */}
				</Slider>
			</div>
		</div>
	);
};

export default Gallery;
