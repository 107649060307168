import {
	FaLinkedin,
	FaInstagram,
	FaEnvelope,
	FaPhoneAlt,
} from "react-icons/fa";
import "./Contactus.css";

const Contactus = () => {
	return (
		<div className="contactus-container">
			<div className="form-container">
				{/* <iframe
					className="form"
					src="https://docs.google.com/forms/d/e/1FAIpQLSe6et1uYy5-dQmgUVx2aUSsz9VHYmKLvg-CAIrW8Yui_FWO8g/viewform?usp=sf_link"
					width="100%"
					height="100"
					frameBorder="0"
					marginHeight="0"
					marginWidth="0"
					title="Google Form"
				/> */}
						<a href="https://docs.google.com/forms/d/e/1FAIpQLSe6et1uYy5-dQmgUVx2aUSsz9VHYmKLvg-CAIrW8Yui_FWO8g/viewform" target="_blank" rel="noopener noreferrer">
  Careers
</a>

			</div>
			<div className="contact-container">
				<div className="map">
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7776.887850948083!2d80.142314!3d12.943421!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525e54d1c412bf%3A0x57f396120d328419!2s1%2C%20Hanumar%20Koil%20St%2C%20Gajalakshmi%20Colony%2C%20Chromepet%2C%20Chennai%2C%20Tamil%20Nadu%20600044%2C%20India!5e0!3m2!1sen!2sus!4v1689526676908!5m2!1sen!2sus"
						width="400"
						height="200"
						style={{ border: 0 }}
						allowFullScreen=""
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade"
					></iframe>
				</div>
				<div className="social-icons">
					<a href="tel:+91 8056131009">
						<FaPhoneAlt />
					</a>
					<a
						href="https://www.linkedin.com/company/bearly-technovations-pvt-ltd/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaLinkedin />
					</a>
					<a
						href="https://www.instagram.com/bearly_technovations/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaInstagram />
					</a>
					<a
						href="mailto:info@bearlytech.com"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaEnvelope />
					</a>
				</div>
			</div>
		</div>
	);
};
export default Contactus;

// Div btw spacing
// supported and reconginzed by
// mobile number
// services arrow gap
// services img tag border check
// contact us (Gap btw icons)

// crew
// margin top 65
// gallery
