import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./Techsec.css";

const Techsec = () => {
	const navigate = useNavigate();
	const oncontactClick = useCallback(() => {
		navigate("/contact-us");
	}, [navigate]);

	const ontechClick = useCallback(() => {
		navigate("/product");
	}, [navigate]);

	const onservClick = useCallback(() => {
		navigate("/services");
	}, [navigate]);

	return (
		<div className="techsec">
			<div className="techsecimage-container">
				<img src="./objectother-18@2x.png" alt="Slide 1" />
			</div>
			<div className="techbanner-text">
				<h7>TECHNOLOGY</h7>
				<h1>
					Revolutionizing Gait Disorder Diagnosis And Management With paceB
				</h1>
				<p>
					Our holistic solution seamlessly integrates AI and gait analysis to
					provide unprecedented insights into gait disorders, from early
					detection to advanced stages.{" "}
				</p>
				<p className="tech-sub-desc">
					paceB is the future of gait disorder care.
				</p>
				<button onClick={ontechClick}>KNOW MORE</button>
			</div>
		</div>
	);
};
export default Techsec;
