import { useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./Footer.css";
const Footer = () => {
	const navigate = useNavigate();

	const onLogoContainerClick = useCallback(() => {
		navigate("/");
	}, [navigate]);

	const onServicesContainerClick = useCallback(() => {
		navigate("/services");
	}, [navigate]);

	const onTechnologyContainerClick = useCallback(() => {
		navigate("/product");
	}, [navigate]);

	const onCareersContainerClick = useCallback(() => {
		navigate("/careers");
	}, [navigate]);

	const onContactContainerClick = useCallback(() => {
		navigate("/contact-us");
	}, [navigate]);

	const onTACContainerClick = useCallback(() => {
		navigate("/terms-and-conditions");
	}, [navigate]);

	const onFAQSContainerClick = useCallback(() => {
		navigate("/faqs");
	}, [navigate]);

	return (
		<div>
			<div className="Footer">
				<div className="Footer_logo">
					<img
						className="Logo_img"
						alt=""
						src="./Footer_logo.png"
						onClick={onLogoContainerClick}
					></img>
				</div>
				<div className="Footer_nav">
					<div className="Footer_column">
						<li id = "li2" className="Footer_Details" onClick={onTechnologyContainerClick}>
							Product
						</li>
						{/* <li className="Footer_Details" onClick={onServicesContainerClick}>
							Services
						</li> */}
						<a
							href="https://docs.google.com/forms/d/e/1FAIpQLScLR-v2vJYy7kz-ip97fCeJxL3SpQCx6YRW1lK2bYe9-Iw6Mg/viewform?usp=sf_link"
							target="_blank"
						>
							<li id = "li2" className="Footer_Details">Careers</li>
						</a>

						{/* <a href="https://docs.google.com/document/d/1i5mZGiAPR38sY-F_-tbpHjcvIhO4RqYm/edit?usp=sharing&ouid=103106824960270370000&rtpof=true&sd=true">
						<li>Terms and Conditions</li>
						</a> */}
						<li id = "li2" className="Footer_Details" onClick={onTACContainerClick}>
						Terms and Conditions
						</li>

						<li id = "li2" className="Footer_Details" onClick={onFAQSContainerClick}>
						FAQs
						</li>

						{/* <a href="https://docs.google.com/document/d/1tLix8isyJG-xybaq38RwvN0evHMWhF22R5v9S8r2OMo/edit?usp=sharing">
						<li id = "li2" >FAQs</li>
						</a> */}
						
					</div>
					<div className="Footer_column">
						<a href="tel:+91 8056131009" target="_blank">
							<li id = "li2" className="Footer_Details">Mobile : +91 8056131009</li>
						</a>
						<a href="mailto:info@bearlytech.com" target="_blank">
							<li id = "li2" className="Footer_Details">Email: info@bearlytech.com</li>
						</a>
						<a href="https://goo.gl/maps/BpxTppm45ErXtc7NA" target="_blank">
							<li id = "li2" className="Footer_Details">
								Address: 1, Hanumar Koil Street, Nehru Nagar, Chrompet,
								Chennai-600044
							</li>
						</a>
					</div>
					<div className="Footer_column">
						<li id = "li2" className="bold">Follow us:</li>
						<a
							href="https://www.instagram.com/bearly_technovations/"
							target="_blank"
						>
							<li id = "li2">Instagram</li>
						</a>
						<a
							href="https://www.linkedin.com/company/bearly-technovations-pvt-ltd/"
							target="_blank"
						>
							<li id = "li2">Linkedin</li>
						</a>
					</div>
				</div>
			</div>
			<div className="rights">
				<p id = "para" className="rights">©bEarly TechnoVations Pvt. Ltd.</p>
			</div>

		</div>
	);
};
export default Footer;
