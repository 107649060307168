import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./Banner.css"
const Banner = () => {
    const navigate = useNavigate();
  

    return(
        <div className = "banner">
            <div className = "banner_text">
                <div className = "banner_header">
                    <h1>Where Thoughts Transcend</h1>
                </div>
                <p className = "banner_desc">We harness the power of technology to disrupt the status quo and create transformational change.</p>
                <div className = "banner_buttons">
                    {/* <button className =  "button1" onClick={oncontactClick}>GET STARTED</button> */}
                    {/* <button className =  "button3">KNOW MORE</button> */}
                    {/* <button className =  "button2" onClick={ontechClick}>KNOW MORE</button> */}
                </div>
            </div>
            <div className = "banner_img">
                <img className = "ban_img" alt = "" src = "./objectother-0711@2x.png"/>
            </div>
        </div>
    )
}
export default Banner;