import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Services from "./pages/Services2";
import ContactUs from "./pages/ContactUs";
import Technology from "./pages/Technology";
import Blog from "./pages/Blog";
import { useEffect } from "react";
import Blog1 from "./components/Blog/Blog1";
import TermsAndConditions from "./pages/Toc";
import Tac from "./pages/Tac";
import Faqs from "./pages/Faqs"

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      // case "/services":
      //   title = "";
      //   metaDescription = "";
      //   break;
      case "/contact-us":
        title = "";
        metaDescription = "";
        break;
      case "/product":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      {/* <Route path="/services" element={<Services />} /> */}
      <Route path="/terms-and-conditions" element={<Tac />} />
      <Route path="/faqs" element={<Faqs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/product" element={<Technology />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/:blogid" element={<Blog1 />} />
      
    </Routes>
  );
}
export default App;
