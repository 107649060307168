import "./Features.css";

const Features = () => {
	return (
		<div className="features">
			<div className="features-item-main">
				<div className="features-item">
					<div>
						<h1>Real-time Gait Monitoring</h1>
						<p className="feat-desc">
							HealthCare professionals can visualise the peak pressure from
							different parts of the foot as the patient walks.
						</p>
					</div>
					<img src="11.png" alt="Logo 1" className="feat-logo" />
				</div>
				<div className="features-item">
					<div>
						<h1>Non-invasive</h1>
						<p className="feat-desc">
							Our product uses non-invasive sensors to track your gait, making
							it a safe and affordable way to assess your gait health.
						</p>
					</div>
					<img src="10.png" alt="Logo 1" className="feat-logo" />
				</div>
			</div>
			<div className="features-item-main">
				<div className="features-item">
					<div>
						<h1>gait disorder screening</h1>
						<p className="feat-desc">
						Traditional visual inspection based gait assessment being 
						limited in terms of information, paceB measures gait parameters 
						with its precision sensors to quantify different 
						gait abnormalities experienced by patients during analysis.
						</p>
					</div>
					<img src="12.png" alt="Logo 1" className="feat-logo" />
				</div>
				<div className="features-item">
					<div>
						<h1>Prognostication with detection of abnormal gait</h1>
						<p className="feat-desc">
						PaceB's quantitative prognosis feature, with its precise 
						measurement of patients' treatment response, indicates 
						improvement and deterioration in mobility, enabling 
						healthcare professionals to optimise their treatment 
						options and track patients' response to treatment 
						thereby, improving patient outcomes.
						</p>
					</div>
					<img src="13.png" alt="Logo 1" className="feat-logo" />
				</div>
			</div>
		</div>
	);
};
export default Features;
