import "./Features.css";
import "./Intheworks.css";
const Intheworks = () => {
	return (
		<div className="works">
			<div className="features-item-main">
				<div className="features-item">
					<div>
						<h1>Early diagnosis and risk assessment</h1>
						<p className="feat-desc">
							Our system facilitates early root-cause identification of gait
							disorders, before they become more serious.
						</p>
					</div>
					<img src="15.png" alt="Logo 1" className="works-logo" />
				</div>
				<div className="features-item">
					<div>
						<h1>targeted interventions</h1>
						<p className="feat-desc">
							Enabling targeted interventions: Our system can help to identify
							patients who are most likely to benefit from gait correction or
							improvement interventions to prioritize patients for treatment and
							ensure that they receive the care they need.
						</p>
					</div>
					<img src="16.png" alt="Logo 1" className="works-logo" />
				</div>
			</div>
			<div className="features-item-main">
				<div className="features-item">
					<div>
						<h1>gait correction and improvement</h1>
						<p className="feat-desc">
							Indications for gait correction and improvement: Our system can
							identify areas where gait needs to be corrected or improved to
							develop personalized gait rehabilitation programs.
						</p>
					</div>
					<img src="17.png" alt="Logo 1" className="works-logo" />
				</div>
			</div>
		</div>
	);
};
export default Intheworks;
