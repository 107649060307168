import React from "react";
import styles from  "./BlogComponent.module.css";
import { useNavigate } from "react-router-dom";
function Card ({authors,datePublished,title,content,imgSrc,blogid})  {
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate("/blog/"+blogid,blogid);
      };
    return (
        <div className={styles.cardContainer} onClick={handleNavigate}>
        <div className={styles.nameDet}>
            <h3 className={styles.name} >{title}</h3>
             
           

        </div>
        <p className="" >{content}</p>
     
    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
    {/* <img src={imgSrc} alt="img" className="img" /> */}
    </div>
    <p className={styles.text1} > Authors: {authors}</p>
        <p className={styles.text1} >Date Published: {datePublished}</p>

    </div>

    )
};
export default Card;